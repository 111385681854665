<template>
  <v-row wrap no-gutters>
    <credentials-select
      :key="`${step.id}-credentialname`"
      step-type="GROOVY"
      :properties="properties"
      step-label="groovy"
      :readonly="!canEdit"
      :required="false"
      @change="handleChange('credentialName', $event)"
    />
    <libraries-select
      :key="`${step.id}-libraries`"
      step-type="GROOVY"
      step-label="groovy"
      :properties="properties"
      :readonly="!canEdit"
      @change="handleChange('librariesNames', $event)"
    />

    <v-col cols="12" class="py-1">
      <add-key-value
        :key="`${step.id}-set`"
        step-type="GROOVY"
        type="groovy"
        :can-edit="canEdit"
        :steps="steps"
        :single-step="step"
        :data="properties.set"
        @editor="openEditor($event, 'set', type)"
        @dataChanged="handleChange('set', $event)"
      ></add-key-value>
    </v-col>
  </v-row>
</template>

<script>
import { CredentialsSelect, LibrariesSelect } from './components'
import AddKeyValue from '../KeyValPairModal'
import StepMixin from './StepMixin'

export default {
  name: 'GroovyStep',
  components: {
    CredentialsSelect,
    LibrariesSelect,
    AddKeyValue
  },
  mixins: [StepMixin],
  props: {
    steps: {
      type: Array,
      default: () => []
    }
  }
}
</script>
